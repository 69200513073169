import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dateHelper from "../../../../_artifact/helpers/dateHelper";
import jsonHelper from "../../../../_artifact/helpers/jsonHelper";
import { testResultsDummy } from "../../../../_artifact/helpers/tableHelper";

const initialState = {
  loader: false,
  totalCount: 0,
  resultsdata: [],
  specialistInfo: {},
  lastFetch: null,
};

const tempState = {
  loader: false,
  totalCount: 0,
  resultsdata: [],
  testCode: "",
  lastFetch: null,
};

// Slice
export const testresultsSlice = createSlice({
  name: "testresults",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loader = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loader = true;
    },
    cleared: (state) => {
      return {
        ...tempState,
        // resultsdata: state.resultsdata,
        testCode: state.testCode,
      };
    },
    testClick: (state, action) => {
      const { testCode } = action.payload;
      state.testCode = testCode;
    },
    // findResults
    testResultsFetched: (state, action) => {
      const { resultsdata } = action.payload;
      state.loader = false;
      state.error = null;
      state.resultsdata = resultsdata;
    },
    specialistInfoFetched: (state, action) => {
      const { entities } = action.payload;
      state.loader = false;
      state.error = null;
      state.specialistInfo = entities;
    },
    cleardataresults: (state, action) =>{
      state.resultsdata = [];
    }
  },
});

// Actions
const { actions, reducer } = testresultsSlice;
export const { testClick, testResultsFetched } = actions;
export default reducer;

export const clear = () => (dispatch) => dispatch(actions.cleared());

export const fetchTestResults = () => (dispatch) => {
  dispatch(actions.startCall());
  return testResults()
    .then((response) => {
      const resultsdata = response.data;
      dispatch(actions.testResultsFetched({ resultsdata }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find results";
      dispatch(actions.catchError({ error }));
    });
};

export const fetchSpecialistInfo = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return getSpecialistInfo(request)
    .then((response) => {
      dispatch(actions.specialistInfoFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find specialist info";
      dispatch(actions.catchError({ error }));
    });
};

// Crud
export const RESULTS_URL = "specialist";

function testResults() {
  return axios.get(`${RESULTS_URL}/testResults/`);
}

function getSpecialistInfo(request) {
  return axios.post(`${RESULTS_URL}/specialistInfo/`, request);
}

export const exportdataresults = () => (dispatch) =>{
  dispatch(actions.cleardataresults());
}
