import MockAdapter from "axios-mock-adapter";
import mockAuth from "../../app/modules/Auth/__mocks__/mockAuth";
import mockPets from "../../app/modules/Testing/__mocks__/mockApi/mockPet";
import mockPreviousTests from "../../app/modules/Testing/__mocks__/mockApi/mockPreviousTest";

export default function mockAxios(axios) {
  const mock = new MockAdapter(axios, { delayResponse: 300 });

  mockAuth(mock);
  mockPets(mock);
  mockPreviousTests(mock);

  return mock;
}
