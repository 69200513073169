import previousTestTableMock from "../mockDb/previousTestTableMock";
// import MockUtils from "../mock.utils";
import { PREVIOUS_TESTS_URL } from "../../_redux/previousTests";

export default function mockPreviousTest(mock) {
  // mock.onPost("api/previousTests").reply(({ data }) => {
  //   const { previousTest } = JSON.parse(data);
  //   const { name = "" } = previousTest;

  //   const id = generateUserId();
  //   const newPreviousTest = {
  //     id,
  //     name,
  //   };
  //   previousTestTableMock.push(newPreviousTest);
  //   return [200, { previousTest: newPreviousTest }];
  // });

  mock.onPost(PREVIOUS_TESTS_URL).reply((config) => {
    // const mockUtils = new MockUtils();
    const request = JSON.parse(config.data);
    console.log("request", request);
    // const filterdPreviousTests = mockUtils.baseFilter(previousTestTableMock, request);
    return [200, previousTestTableMock];
  });
}

// function generateUserId() {
//   const ids = previousTestTableMock.map((el) => el.id);
//   const maxId = Math.max(...ids);
//   return maxId + 1;
// }
