import jsonHelper from "../../../../_artifact/helpers/jsonHelper";
import {
  AUTH_URL,
  ME_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL,
} from "../_redux/authRedux";
import userTableMock from "./userTableMock";

export default function mockAuth(mock) {
  mock.onPost(`${AUTH_URL}/user_login/`).reply(({ data }) => {
    // const { email, password } = JSON.parse(data);
    const { email, password } = jsonHelper.formToJson(data);

    if (email && password) {
      const user = userTableMock.find(
        (x) => x.data.panelist_email_id.toLowerCase() === email.toLowerCase()
        // && x.user.password === password
      );

      if (user) {
        // const userInDb = JSON.parse(JSON.stringify(user));
        // delete userInDb.user.password;
        return [200, user];
      }
    }

    return [400];
  });

  mock.onPost(REGISTER_URL).reply(({ data }) => {
    const { email, fullname, username, password } = JSON.parse(data);

    if (email && fullname && username && password) {
      const user = {
        id: generateUserId(),
        email,
        fullname,
        username,
        password,
        firstname: fullname,
        lastname: "Stark",
        roles: [2], // Manager
        authToken: "auth-token-" + Math.random(),
        refreshToken: "auth-token-" + Math.random(),
        pic: process.env.PUBLIC_URL + "/media/users/default.jpg",
      };

      userTableMock.push(user);

      return [200, { ...user, password: undefined }];
    }

    return [400];
  });

  mock.onPost(REQUEST_PASSWORD_URL).reply(({ data }) => {
    const { email } = JSON.parse(data);

    if (email) {
      const user = userTableMock.find(
        (x) => x.email.toLowerCase() === email.toLowerCase()
      );

      if (user) {
        user.password = undefined;

        return [200, { ...user, password: undefined }];
      }
    }

    return [400];
  });

  mock
    .onGet(`${AUTH_URL}/login_refresh/`)
    .reply(({ headers: { Authorization } }) => {
      const authToken =
        Authorization &&
        Authorization.startsWith("JWT ") &&
        Authorization.slice("JWT ".length);

      if (authToken) {
        const user = userTableMock.find((x) => x.id_token === authToken);

        console.log("user", user);

        if (user) {
          return [200, { ...user.data }];
        }
      }

      return [401];
    });

  function generateUserId() {
    const ids = userTableMock.map((el) => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
}
