import axios from "axios";
import React, { useState, useEffect } from "react";
import { Image } from "antd";

// Global cache object to store image responses
const imageCache = {};

export default function CustomImage({ blobUrl }) {
  const [imageSrc, setImageSrc] = useState(imageCache[blobUrl] || null);

  useEffect(() => {
    // Prevent API call if blobUrl is invalid
    if (!blobUrl || blobUrl === undefined || blobUrl.includes("/null") || blobUrl.includes("undefined")) return;

    if (imageCache[blobUrl]) return; // Don't fetch if already cached
    let URL_Image=`${process.env.REACT_APP_API_URL}/specialist/fetchImage/`
    axios
      .post(URL_Image, { blob_url: blobUrl })
      .then((response) => {
        if (response.data.image) {
          imageCache[blobUrl] = response.data.image; // Store in cache
          setImageSrc(response.data.image);
        }
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  }, [blobUrl]); // Only runs if blobUrl changes

  // Early return if blobUrl is invalid
  if (!blobUrl || blobUrl === undefined || blobUrl.includes("/null") || blobUrl.includes("/undefined")) {
    return null;
  }

  return imageSrc ? (
    <Image width={80} src={imageSrc} preview={{ src: imageSrc }} />
  ) : null;
}
