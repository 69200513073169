import { persistReducer } from "redux-persist";
import session from "redux-persist/lib/storage/session";
import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import jsonHelper from "../../../../_artifact/helpers/jsonHelper";
import { toast } from "react-toastify";
import { Translate } from "../../../../_artifact/i18n/Translate";
axios.defaults.withCredentials = true;
export const actionTypes = {
  Login: "[Login] Action",
  MethodologistLogin: "[MethodologistLogin] Action",
  Logout: "[Logout] Action",
  Language: "[Language] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  MethodologistRequested: "[Request Methodologist] Action",
  UserLoaded: "[Load User] Auth API",
  MethodologistLoaded: "[Load Methodologist] Auth API",
  SetUser: "[Set User] Action",
  ForgotPassword: "[Forgot Password] Action",
  VerifyOtp: "[Verify Otp] Action",
  StartCall: "[Start Call] Action",
  CallCompleted: "[Call Completed] Action",
  CatchError: "[Catch Error] Action",
};

const initialAuthState = {
  user: null,
  authToken: undefined,
  forgotUser: {
    email: null,
    authId: null,
  },
  loading: false,
  error: undefined,
};

export const reducer = persistReducer(
  { storage: session, key: "auth-storage", whitelist: ["authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { id_token, data } = action.payload;
        data.first_name = data.panelist_name.split(" ")[0];
        return { authToken: id_token, user: data };
      }
      case actionTypes.Language:
        const users = { ...state?.user };
        users.language = action.payload;
        return { ...state, user: users };

      case actionTypes.MethodologistLogin: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        // TODO: Logout the user if authToken has expired
        if (user) user.first_name = user.panelist_name.split(" ")[0];
        return { ...state, user };
      }

      case actionTypes.MethodologistLoaded: {
        const { user } = action.payload;
        // TODO: Logout the user if authToken has expired
        if (user) {
          if (user[0]) {
            user[0].first_name = user[0].full_name.split(" ")[0];
            user[0].isSpecialist = true;
          }
        }
        return { ...state, user: user[0] };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.ForgotPassword: {
        const { forgotUser } = action.payload;
        return { ...state, forgotUser, loading: false };
      }

      case actionTypes.VerifyOtp: {
        const { authId } = action.payload;
        return {
          ...state,
          forgotUser: { ...state.forgotUser, authId },
          loading: false,
        };
      }

      case actionTypes.StartCall: {
        return { ...state, loading: true, error: undefined };
      }

      case actionTypes.CallCompleted: {
        return { ...state, loading: false, error: undefined };
      }

      case actionTypes.CatchError: {
        const { error } = action.payload;
        toast.error(error.clientMessage);
        return { ...state, error, loading: false };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => {
    return { type: actionTypes.Login, payload: authToken };
  },
  methodologistLogin: (authToken) => ({
    type: actionTypes.MethodologistLogin,
    payload: { authToken },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  changeLanguage: (language) => ({
    type: actionTypes.Language,
    payload: language,
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  requestMethodologist: (user) => ({
    type: actionTypes.MethodologistRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fulfillMethodologist: (user) => ({
    type: actionTypes.MethodologistLoaded,
    payload: { user },
  }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  forgotPassword: (forgotUser) => ({
    type: actionTypes.ForgotPassword,
    payload: { forgotUser },
  }),
  verifyOtp: (authId) => ({
    type: actionTypes.VerifyOtp,
    payload: { authId },
  }),
  startCall: () => ({
    type: actionTypes.StartCall,
  }),
  callCompleted: () => ({
    type: actionTypes.CallCompleted,
  }),
  catchError: (error) => ({
    type: actionTypes.CatchError,
    payload: { error },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    const authToken = localStorage.getItem("tok");
    if (authToken) {
      yield put(actions.requestUser());
    }
  });

  yield takeLatest(
    actionTypes.MethodologistLogin,
    function* methodologistLoginSaga() {
      yield put(actions.requestMethodologist());
    }
  );

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   console.log("registerSaga");
  //   yield put(actions.requestUser());
  // });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    try {
      const { data: user } = yield getUserByToken();
      yield put(actions.fulfillUser(user));
    } catch (error) {
      console.log("Refresh Token Error -->", error);
      yield put(actions.fulfillUser(null));
    }
  });

  yield takeLatest(
    actionTypes.MethodologistRequested,
    function* methodologistRequested() {
      // const { data: user } = yield getMethodogistByToken();
      const { data: user } = yield getSSOByToken();
      yield put(actions.fulfillMethodologist(user));
    }
  );
}

// Thunk

export const fetchForgotPassword = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return postForgotPassword(request).then(({ data: { authId } }) => {
    const email = jsonHelper.formToJson(request).email;
    dispatch(actions.forgotPassword({ email, authId }));
  });
};

export const fetchVerifyOtp = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return postVerifyOtp(request).then(({ data: { authId } }) => {
    dispatch(actions.verifyOtp(authId));
  });
};

export const fetchResetPassword = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return postResetPassword(request).then(() => {
    dispatch(actions.logout());
  });
};

export const updateAuthError = (error) => (dispatch) =>
  dispatch(actions.catchError(error));

// Crud

export const LOGIN_URL = "api/token/";
// export const REGISTER_URL = "api/auth/register";
// export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
// export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;
export const AUTH_URL = "login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

export function login(user) {
  return axios.post(`${AUTH_URL}/user_login/`, user,{'withCredentials': true });
}

export function methodologistLogin(user) {
  return axios.post(`${AUTH_URL}/specialist_login/`, user);
}

export function ssoLogin() {
  return axios.get(`${AUTH_URL}/sso_token`,{'withCredentials': true });
}

// export function register(email, fullname, username, password) {
//   return axios.post(REGISTER_URL, { email, fullname, username, password });
// }

// export function requestPassword(email) {
//   return axios.post(REQUEST_PASSWORD_URL, { email });
// }

export function getUserByToken() {
  // console.log("getUserByToken");
  return axios.get(`${AUTH_URL}/login_refresh/`);
}

export function getMethodogistByToken() {
  // console.log("getMethodogistByToken");
  return axios.get(`${AUTH_URL}/specialist_refresh/`);
}

export function getSSOByToken() {
  // console.log("getMethodogistByToken");
  // const email = localStorage.getItem("email"); // Get the email from localStorage
  return axios.get(`${AUTH_URL}/userinfo`,{'withCredentials': true });
}

export function changePassword(request) {
  return axios.post(`${AUTH_URL}/change_password/`, request);
}

export function postForgotPassword(request) {
  return axios.post(`${AUTH_URL}/forgot_password/`, request);
}

export function postVerifyOtp(request) {
  return axios.post(`${AUTH_URL}/verify_otp/`, request);
}

export function postResetPassword(request) {
  return axios.post(`${AUTH_URL}/reset_password/`, request);
}

export function getOneTrust() {
  return axios.get(`${AUTH_URL}/one_trust`);
}
