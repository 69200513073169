import React from "react";
import { useState, useEffect } from "react";
import {
  SearchOutlined,
  FilterOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MailOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Table,
  Input,
  Form,
  Space,
  Button,
  Checkbox,
  Popover,
  Tooltip,
  Image,
  InputNumber,
  Modal,
  Select,
} from "antd";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Comment from "../../../../assests/images/CommentBox.svg";
import Highlighter from "react-highlight-words";
import {
  addCommentTest,
  fetchTestResultsDetailed,
  scoreTestDetailed,
} from "../../../../app/modules/Methodologist/_redux/resultsDetailed";
import "../../../../app/modules/Methodologist/pages/results/Results.scss";
import { statusComponent } from "../../../helpers/tableHelper";
import CommentBox from "../../../../_artifact/components/methodologist/commentBox/index";

import "../../../../app/modules/Methodologist/pages/panelists/Panelists.scss";
import { fetchTestKPIs } from "../../../../app/modules/Methodologist/_redux/resultsDetailed";
import { testClick } from "../../../../app/modules/Methodologist/_redux/results";
import { editAdmin } from "../../../../app/modules/Methodologist/_redux/manage";
import { openNotificationWithIcon } from "../../../helpers/tableHelper";
import CustomImage from "../../CustomImage";

const { Option } = Select;

export default function MethodologistTable(props) {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [form] = Form.useForm();
  const [searchInput, setSearchInput] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [rec, setRec] = useState(null);
  const [editingKey, setEditingKey] = useState(null);
  const [comment, setComment] = useState(null);
  const [commentModalOpen, setCommentModalOpen] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState("name");
  const [inactiveReason, setInactiveReason] = useState("");
  const [inactiveReasonModalOpen, setInactiveReasonModalOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [filteredValues, setFilteredValues] = useState({});
  const [finalFilteredValues, setFinalFilteredValues] = useState({});
  const [filteredDropdowns, setFilteredDropdown] = useState({});
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const { email_id } = state.auth.user;

  useEffect(() => {
    if (!rec || !props.data) return;

    const newRec = props.data.find(
      (record) => record[props.rowIdentifier] === rec[props.rowIdentifier]
    );
    if (!newRec) return;
    setRec(newRec);
    setComment(newRec[props.commentKey]);
  }, [props.data, rec]);

  useEffect(() => {
    setSearchText("");
  }, [props.filterCurrent]);

  const edit = (record) => {
    console.log(
      "Temp Record",
      record,
      props.rowIdentifier,
      record[props.rowIdentifier]
    );
    form.resetFields();
    let tempRecord = { ...record };
    if (tempRecord.status) {
      setStatus(tempRecord.status);
    }
    if (tempRecord.status_comment) {
      setInactiveReason(tempRecord.status_comment);
    }
    form.setFieldsValue({
      ...tempRecord,
    });
    setEditingKey(record[props.rowIdentifier]);
  };

  const cancel = () => {
    setInactiveReason("");
    setEditingKey(null);
    setStatus("");
  };

  const save = async (key) => {
    try {
      let row = await form.validateFields();
      const index = tableData.findIndex(
        (item) => key === item[props.rowIdentifier]
      );
      if (index > -1) {
        const item = tableData[index];
        console.log("Save Record", key, item);
        if (row.status) {
          row = { ...row, status: status };
        }
        dispatch(
          editAdmin(index, { ...item, ...row, inactivereason: inactiveReason })
        );
      }
      setInactiveReason("");
      setEditingKey(null);
      setStatus("");
    } catch (errInfo) {
      openNotificationWithIcon(
        "error",
        "Validation Failed. Please check the input fields."
      );
      console.log("Validate Failed:", errInfo);
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    key,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode =
      inputType === "number" ? (
        <InputNumber />
      ) : title && title.toLowerCase().includes("comments") ? (
        <Input.TextArea />
      ) : (
        <Input />
      );

    if (dataIndex === "status") {
      inputNode = (
        <div style={{ display: "flex" }}>
          <Select
            value={status}
            allowClear
            dropdownStyle={{ position: "relative" }}
            onChange={(e) => {
              if (e === "Inactive") {
                setInactiveReasonModalOpen(true);
              }
              setStatus(e);
            }}
          >
            {(record.status === "Inactive"
              ? ["Inactive"]
              : ["Inactive", "Active"]
            ).map((val, i) => (
              <Option key={i} value={val}>
                {val}
              </Option>
            ))}
          </Select>
          {status === "Inactive" && (
            <a
              style={{ paddingLeft: "10%" }}
              onClick={(e) => setInactiveReasonModalOpen(true)}
            >
              <img src={Comment} />
            </a>
          )}
        </div>
      );
    }
    // else if (props["filtr_" + dataIndex] !== undefined) {
    //   inputNode = (
    //     <Select allowClear dropdownStyle={{ position: "relative" }}>
    //       {props["filtr_" + dataIndex].map((val, i) => (
    //         <Option key={i} value={val}>
    //           {val}
    //         </Option>
    //       ))}
    //     </Select>
    //   );
    // }

    if (dataIndex === "status") {
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: false,
                  message: `Please Input ${title}!`,
                },
                {
                  validator: (_, val) => {
                    return status.toLowerCase() !== "inactive" ||
                      inactiveReason !== ""
                      ? Promise.resolve()
                      : Promise.reject(new Error("Inactive reason required"));
                  },
                },
              ]}
            >
              {inputNode}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: false,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  useEffect(() => {
    fillDropdowns();
  }, [props.data, props.filterCurrent]);

  useEffect(() => {
    setFilteredValues({});
    setFinalFilteredValues({});
    fillDropdowns();
  }, [props.clearFilters]);

  const applyFilters = (confirm, dataIndex) => {
    confirm();
    setFinalFilteredValues({ ...filteredValues });
    let temp = [...tableData];
    let tempDropdowns = { ...filteredDropdowns };
    props.columns
      ?.map((val) => val.dataIndex)
      .forEach((val) => {
        let temp2 = [...temp];
        props.columns
          ?.map((val) => val.dataIndex)
          .forEach((val2) => {
            if (val2 !== val) {
              temp2 = temp2.filter((val3) => {
                if (filteredValues[val2])
                  return filteredValues[val2].includes(val3[val2]);
                else return true;
              });
            }
          });
        tempDropdowns[val] = Array.from(
          new Set(temp2?.map((val2) => val2[val]))
        ).sort((a, b) => (a < b ? -1 : 1));
        //   console.log(val, filteredValues[val], tempDropdowns[val]);
      });
    setFilteredDropdown(tempDropdowns);
  };

  // const transformedDataFunction = (data) => {
  //   return (
  //     data ? [] : data.map(val => ({
  //       ...val,
  //       rowKey: val.pet_name.concat(val.product_id),
  //     })))
  // }

  const approve = (length) => (
    <div className="approve-reject">
      <h4 style={{ paddingTop: "10px" }}>Selected Items: {length}</h4>
      <Button
        type="secondary"
        icon={<CheckCircleTwoTone twoToneColor="#00C853" />}
        onClick={() => {
          setSelectedRows([]);
          dispatch(
            scoreTestDetailed(
              selectedRows,
              "Approved",
              tableData,
              state.overviewData.specialistInfo.country
            )
          );
        }}
        className="approve"
      >
        Approve
      </Button>
      <Button
        type="secondary"
        icon={<CloseCircleTwoTone twoToneColor="#D24D5B" />}
        onClick={() => {
          setSelectedRows([]);
          dispatch(scoreTestDetailed(selectedRows, "Rejected", tableData));
        }}
        className="reject"
      >
        Reject
      </Button>
    </div>
  );

  function handleFilterReset(clearFilters, dataIndex) {
    setFilteredDropdown({
      ...filteredDropdowns,
      [dataIndex]: Array.from(
        new Set(tableData?.map((val2) => val2[dataIndex]))
      ).sort((a, b) => (a < b ? -1 : 1)),
    });
    setFilteredValues({
      ...filteredValues,
      [dataIndex]: null,
    });
    setFinalFilteredValues({
      ...filteredValues,
      [dataIndex]: null,
    });
  }

  const getColumnFilterProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <>
        <div className="custom-filter-dropdown">
          {(filteredDropdowns[dataIndex] || []).map((k, index) => (
            <div key={index}>
              <Checkbox
                checked={(filteredValues[dataIndex] || []).includes(k)}
                onChange={(e) => {
                  // console.log("Filtered Values", filteredValues);
                  let temp = [...(filteredValues[dataIndex] || [])];
                  if (e.target.checked) {
                    temp.push(k);
                  } else {
                    // console.log(temp, val);
                    let ind = temp.findIndex((x) => x === k);
                    if (ind > -1) {
                      temp.splice(ind, 1);
                    }
                  }
                  //   console.log(temp);
                  setFilteredValues({
                    ...filteredValues,
                    [dataIndex]: temp,
                  });
                }}
              >
                {k}
              </Checkbox>
            </div>
          ))}
        </div>
        <div className="filter-buttons">
          <Button
            type="primary"
            onClick={() => applyFilters(confirm, dataIndex)}
            icon={<FilterOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              handleFilterReset(clearFilters, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      </>
    ),
    filteredValue: finalFilteredValues[dataIndex] || null,
    onFilter: (value, record) => record[dataIndex] === value,
    render: tableRender(dataIndex, title),
  });

  const fillDropdowns = () => {
    let tempDropdowns = {};
    props.columns
      ?.map((k) => k.dataIndex)
      ?.forEach((val) => {
        tempDropdowns[val] = Array.from(
          new Set(tableData?.map((val2) => val2 && val2[val]))
        ).sort((a, b) => (a < b ? -1 : 1));
      });
    setFilteredDropdown(tempDropdowns);
  };

  const tableRender = (dataIndex, title) => (text, record) => {
    if (dataIndex === "test_status_type") {
      return (
        <Space size="middle">
          {
            <>
              <div
                className={`status-box width100 ${
                  !text ? "" : text.toLowerCase()
                }`}
              >
                {text}
              </div>
            </>
          }
        </Space>
      );
    } else if (dataIndex === "test_id") {
      return text;
      // return searchedColumn ? (
      //   <Highlighter
      //     highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
      //     searchWords={[props.search ? props.search : searchText]}
      //     autoEscape
      //     textToHighlight={text ? text.toString() : ""}
      //   />
      // ) : (
      //   text
      // );
    } else if (dataIndex === "question_responses") {
      return (
        <Space>
          {record.question_responses &&
            JSON.parse(record.question_responses)[title]}
        </Space>
      );
    } else if (dataIndex === "specialist_comments") {
      return text && text.length > 13 ? (
        <>
          <div>
            {`${text.slice(0, Math.min(text.length, 13))} `}
            <Tooltip placement="right" title={text}>
              {text.length > 13 ? <InfoCircleOutlined /> : <></>}
            </Tooltip>
          </div>
        </>
      ) : (
        <div>{text}</div>
      );
    } else if (dataIndex === "score") {
      return (
        <div>
          {text && text.toLowerCase() === "pending" && (
            <div className="status-pending">Pending</div>
          )}
          {text && text.toLowerCase() === "approved" && (
            <div className="status-approved">Approved</div>
          )}
          {text && text.toLowerCase() === "rejected" && (
            <div className="status-rejected">Rejected</div>
          )}
        </div>
      );
    } else if (dataIndex === "status") {
      return (
        <div>
          {text && text.toLowerCase() === "inactive" && (
            <div className="status-manage-rejected">Inactive</div>
          )}
          {text && text.toLowerCase() === "active" && (
            <div className="status-manage-approved">Active</div>
          )}
        </div>
      );
    } else if (dataIndex === "pre_weight_image") {
      return (
        <div>
          <CustomImage
            blobUrl={`${props.linkstart}${record.pre_image_url}${props.linkend}`} // Construct the blob URL dynamically
          />
        </div>
      );
    } else if (dataIndex === "post_weight_image") {
      return (
        <div>
          <CustomImage
            blobUrl={`${props.linkstart}${record.post_image_url}${props.linkend}`} // Construct the blob URL dynamically
          />
        </div>
      );
    } else if (dataIndex === "pet_image_url_w_sas") {
      return (
        <div>
          <CustomImage
            blobUrl={record.pet_image_url_w_sas} // Use the pre-constructed SAS URL
          />
        </div>
      );
    } else {
      return text;
      // return searchedColumn && dataIndex !== "test_status_type" ? (
      //   <Highlighter
      //     highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
      //     searchWords={[props.search ? props.search : searchText]}
      //     autoEscape
      //     textToHighlight={text ? text.toString() : ""}
      //   />
      // ) : (
      //   text
      // );
    }
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: tableRender(dataIndex, title),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const data_columns = props.columns?.map((val) => {
    if (
      val.title === "Pre-Weight Image" ||
      val.title === "Post-Weight Image" ||
      val.title === "Pet Image"
    ) {
      return {
        ...val,
        render: getColumnFilterProps(val.dataIndex, val.title)["render"],
      };
    }
    let actionValues = props.filter
      ? getColumnFilterProps(val.dataIndex, val.title)
      : getColumnSearchProps(val.dataIndex, val.title);
    return {
      ...val,
      ...actionValues,
      sorter: {
        compare: (a, b) => (a[val.key] < b[val.key] ? -1 : 1),
      },
    };
  });

  const isEditing = (record) => record[props.rowIdentifier] === editingKey;

  function actionClick(rec, action) {
    if (action == "Comment") {
      setRec(rec);
      setComment(rec[props.commentKey]);
      setCommentModalOpen(rec[props.rowIdentifier]);
    } else if (action === "Edit") {
      edit(rec);
    }
  }

  const handlePostComment = (commentVal, sendPanelist) => {
    const index = props.data.findIndex(
      (item) => rec[props.rowIdentifier] === item[props.rowIdentifier]
    );
    if (index > -1) {
      // console.log("Entered Handle Post Index", rec, commentVal, sendPanelist)
      // const item = props.data[index];
      dispatch(addCommentTest(rec, commentVal, sendPanelist));
    } else {
      console.log("edge case rec = ", rec);
    }
    // setCommentModalOpen(null);
  };

  if (props.showActions) {
    data_columns.push({
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: parseInt(props.actionWidth) === NaN ? 100 : props.actionWidth,
      render: (text, record) => {
        const editable = isEditing(record);
        const { rowIdentifier, deleteRow } = props;
        const deletable = rowIdentifier === "email_id";
        const showDeletable = record.email_id !== email_id;
        return (
          <>
            {!deletable &&
              (editable ? (
                <Space size="middle">
                  {
                    <>
                      <a onClick={() => save(record[props.rowIdentifier])}>
                        <CheckOutlined />
                      </a>
                      <a onClick={() => cancel()}>
                        <CloseOutlined />
                      </a>
                    </>
                  }
                </Space>
              ) : (
                <Space size="middle">
                  {
                    <a onClick={(e) => actionClick(record, "Edit")}>
                      <Tooltip title="Edit">
                        <EditOutlined />
                      </Tooltip>
                    </a>
                  }
                </Space>
              ))}
            {deletable && showDeletable && (
              <Space size="middle">
                <a onClick={() => deleteRow(record)}>
                  <Tooltip title="Delete">
                    <DeleteOutlined style={{ color: "#dc3545" }} />
                  </Tooltip>
                </a>
              </Space>
            )}
          </>
        );
      },
    });
  }

  if (props.showComment) {
    data_columns.push({
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: 100,
      render: (text, record) => {
        return (
          <Space size="middle">
            {props.showComment && (
              <Popover
                content={
                  <CommentBox
                    setCommentModalOpen={setCommentModalOpen}
                    comment={comment}
                    handlePostComment={handlePostComment}
                  />
                }
                title={"Add a Comment"}
                trigger="focus"
                visible={commentModalOpen === record[props.rowIdentifier]}
              >
                <a onClick={(e) => actionClick(record, "Comment")}>
                  <Tooltip title="Comment">
                    <img src={Comment} />
                  </Tooltip>
                </a>
              </Popover>
            )}
          </Space>
        );
      },
    });
  }

  let mergedColumns = data_columns.map((col) => {
    if (!col.editable) {
      return col;
    } else {
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "routes" ? "array" : "string",
          dataIndex: col.dataIndex,
          key: col.key,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    }
  });

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys) => {
      console.log(
        `selectedRowKeys:`,
        selectedRowKeys,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRows(selectedRowKeys);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(tableData?.map((val) => val[props.rowIdentifier]));
    },
  };

  let searchValues = props.filterCurrent
    ? props.data
        ?.map((val) =>
          val[props.filterKey]
            .toString()
            .toLowerCase()
            .includes(props.filterCurrent.toString().toLowerCase())
        )
        .includes(true)
      ? true
      : false
    : false;

  let tabFilterSearch =
    props.tabFilter && props.data
      ? props.data.filter((val) =>
          val[props.tabField]
            .toLowerCase()
            .includes(props.tabFilter.toLowerCase())
        )
      : [];

  let tableData =
    (!props.filterCurrent || props.filterCurrent === "") &&
    (!props.tabFilter || props.tabFilter === "all")
      ? props.data
      : !props.filterCurrent || props.filterCurrent === ""
      ? tabFilterSearch
      : !props.tabFilter || props.tabFilter === "all"
      ? searchValues
        ? props.data &&
          props.data.filter((val) =>
            val[props.filterKey]
              .toString()
              .toLowerCase()
              .includes(props.filterCurrent.toString().toLowerCase())
          )
        : props.data &&
          props.data.filter((val) =>
            val[props.filterKey2]
              .toString()
              .toLowerCase()
              .includes(props.filterCurrent.toString().toLowerCase())
          )
      : searchValues
      ? tabFilterSearch.filter((val) =>
          val[props.filterKey]
            .toString()
            .toLowerCase()
            .includes(props.filterCurrent.toString().toLowerCase())
        )
      : tabFilterSearch.filter((val) =>
          val[props.filterKey2]
            .toString()
            .toLowerCase()
            .includes(props.filterCurrent.toString().toLowerCase())
        );

  const rowClick = (record) => ({
    onClick: () => {
      let testCode = record.test_id;
      dispatch(testClick({ testCode }));
      props.history.replace("/methodologist/testresultsdetailed");
    },
  });

  return (
    <>
      <Form form={form} component={false}>
        <Table
          rowKey={(val) => val && val[props.rowIdentifier]}
          rowSelection={
            !props.hideCheck
              ? {
                  type: "checkbox",
                  fixed: true,
                  ...rowSelection,
                }
              : false
          }
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          onRow={props.enableRowClick && rowClick}
          dataSource={tableData}
          pagination={props.pagination}
          scroll={{ y: props.height }}
          className={props.className}
        />
      </Form>
      {selectedRows.length > 0 && !props.hideApprove ? (
        approve(selectedRows.length)
      ) : (
        <></>
      )}
      <Modal
        visible={inactiveReasonModalOpen}
        onOk={() => {
          setInactiveReasonModalOpen(false);
        }}
        onCancel={() => {
          setInactiveReasonModalOpen(false);
        }}
      >
        <div>Please enter reason for inactivation: </div>
        <Input.TextArea
          value={inactiveReason}
          onChange={(e) => setInactiveReason(e.target.value)}
        />
      </Modal>
    </>
  );
}
