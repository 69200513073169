import petTableMock from "../mockDb/petTableMock";
// import MockUtils from "../mock.utils";
import { PETS_URL } from "../../_redux/pets";

export default function mockPet(mock) {
  // mock.onPost("api/pets").reply(({ data }) => {
  //   const { pet } = JSON.parse(data);
  //   const { name = "" } = pet;

  //   const id = generateUserId();
  //   const newPet = {
  //     id,
  //     name,
  //   };
  //   petTableMock.push(newPet);
  //   return [200, { pet: newPet }];
  // });

  mock.onPost(PETS_URL).reply((config) => {
    // const mockUtils = new MockUtils();
    // const request = JSON.parse(config.data);
    // console.log("request", request);
    // const filterdPets = mockUtils.baseFilter(petTableMock, request);
    return [200, petTableMock];
  });
}

// function generateUserId() {
//   const ids = petTableMock.map((el) => el.id);
//   const maxId = Math.max(...ids);
//   return maxId + 1;
// }
